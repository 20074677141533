@media (max-width: 576px) {
    .table > tbody > tr td, .table > thead > tr th  {
        width: 100%
    }
}
td label, .order-header label {
    margin: 0;
    font-size: 0.8rem;
}
td > div {
    margin-bottom: 8px;
}
td button, td select, td input {
    font-size: 0.8rem!important;
}
.row-cols-8 > * {
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    max-width: 12.5%;
}
@media (min-width: 992px) {
    .row-cols-lg-8 > * {
        -ms-flex: 0 0 12.5%;
        flex: 0 0 12.5%;
        max-width: 12.5%;
    }
}
@media (min-width: 1200px) {
    .row-cols-xl-8 > * {
        -ms-flex: 0 0 12.5%!important;
        flex: 0 0 12.5%!important;
        max-width: 12.5%!important;
    }
}
.opening-div > div {
    border: white solid 3px;
}
.opening-div > div:hover {
    border: #ffc107 solid 3px;
}
td input, td select {
    border-color: #6c757d!important;
}
